import {PaillardesEnvironment} from '../app/model/paillardes-environment';

export const environment: PaillardesEnvironment = {
  firebaseAppName: 'paillardes',
  production: true,
  env: 'production',
  useFirebaseEmulators: false,
  apolloDevtools: false,
  baseUrl: 'https://paillardes.app',
  firebaseOptions: {
    apiKey: 'AIzaSyBP3A3Qdn2LOwg-QdiWpOdgectTxGrM9rg',
    projectId: 'paillardes-et-chants',
    messagingSenderId: '739077539428',
    appId: '1:739077539428:web:25675b76e53a6e34c20171',
  },
  appTitle: 'Paillardes et Chants',
  apiRestEndpoint: 'https://api.paillardes.app/api',
  apiGraphqlEndpoint: 'https://api.paillardes.app/bM63yqROBjW7RARe',
  facebookPageUrl: 'https://www.facebook.com/paillardesetchants',
  facebookPageUrlShort: 'https://goo.gl/v9HHB1',
  mailContact: 'contact@paillardes.app',
  facebookMessengerLink: 'https://m.me/paillardesetchants',
  instagramDirectMessageLink: 'https://ig.me/m/paillardes.app',
  whatsAppLink: 'https://wa.me/33767694555',
  kickstarterUrl: 'https://www.buymeacoffee.com/ledzaphter',
  recaptchaV3SiteKey: '6LdbD5wUAAAAAP4hSjwJyC3pul1TgCcCuan4PyDw',
  formSubmissionLink: 'https://forms.gle/TTH3mQuhWmVpoLss7',
  apiRequestMaxResultsNumber: 100,
  facebookAppId: '684159381686437',
  facebookPageId: '552277038270747',
  algoliaAppId: 'CHXO5K6W6F',
  algoliaApiKey: 'e746ef494c93ad3342870f664f190585',
  featureFlipping: {
    thirdPartyAuthentication: true,
    facebookChat: false,
  },
};
